import React, { useState } from 'react';
import { Coffee, Wine, Dumbbell, BedDouble, ArrowRight, ArrowLeft, ArrowDown, Armchair, Calendar } from 'lucide-react';
import TimeBlock from './TimeBlock';
import { SleepState } from './types';
import { format, addDays, startOfDay } from 'date-fns';

interface SleepGridProps {
  sleepStates: SleepState;
  setSleepStates: React.Dispatch<React.SetStateAction<SleepState>>;
}

const SleepGrid: React.FC<SleepGridProps> = ({ sleepStates, setSleepStates }) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionStart, setSelectionStart] = useState<{ day: number; hour: number } | null>(null);
  const [selectionType, setSelectionType] = useState<'asleep' | 'awake'>('asleep');
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [viewMode, setViewMode] = useState<'week' | '14days'>('14days');

  // Generate all 24 hours
  const timeBlocks = Array.from({ length: 24 }, (_, i) => i);
  const days = viewMode === 'week' ? 7 : 14;

  const handleBlockSelect = (hour: number, dayIndex: number) => {
    if (!isSelecting) {
      setIsSelecting(true);
      setSelectionStart({ day: dayIndex, hour });
    } else {
      setIsSelecting(false);
      if (selectionStart) {
        const newStates = { ...sleepStates };
        const startDay = Math.min(selectionStart.day, dayIndex);
        const endDay = Math.max(selectionStart.day, dayIndex);
        const startHour = Math.min(selectionStart.hour, hour);
        const endHour = Math.max(selectionStart.hour, hour);

        for (let d = startDay; d <= endDay; d++) {
          for (let h = startHour; h <= endHour; h++) {
            const key = `${d}-${h}`;
            newStates[key] = {
              ...newStates[key],
              isAsleep: selectionType === 'asleep',
              position: selectionType === 'asleep' ? 'supine' : undefined,
            };
          }
        }
        setSleepStates(newStates);
      }
      setSelectionStart(null);
    }
  };

  return (
    <div className="space-y-4">
      {/* Controls */}
      <div className="flex flex-wrap items-center justify-between gap-4">
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => setSelectionType('asleep')}
            className={`flex items-center space-x-2 px-3 py-1.5 rounded-full ${
              selectionType === 'asleep'
                ? 'bg-indigo-100 text-indigo-600'
                : 'bg-gray-100 text-gray-600'
            }`}
          >
            <BedDouble className="w-4 h-4" />
            <span className="text-sm">Asleep</span>
          </button>
          <button
            onClick={() => setSelectionType('awake')}
            className={`flex items-center space-x-2 px-3 py-1.5 rounded-full ${
              selectionType === 'awake'
                ? 'bg-amber-100 text-amber-600'
                : 'bg-gray-100 text-gray-600'
            }`}
          >
            <Coffee className="w-4 h-4" />
            <span className="text-sm">Awake</span>
          </button>
        </div>

        <div className="flex items-center gap-4">
          <div className="flex items-center space-x-2">
            <Calendar className="w-4 h-4 text-gray-600" />
            <input
              type="date"
              value={format(startDate, 'yyyy-MM-dd')}
              onChange={(e) => setStartDate(startOfDay(new Date(e.target.value)))}
              className="px-2 py-1 border border-gray-300 rounded-lg text-sm"
            />
          </div>
          <select
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value as 'week' | '14days')}
            className="px-2 py-1 border border-gray-300 rounded-lg text-sm"
          >
            <option value="week">7 Days</option>
            <option value="14days">14 Days</option>
          </select>
        </div>
      </div>

      {/* Grid */}
      <div className="overflow-auto">
        <div className="grid grid-cols-[auto_repeat(24,_minmax(50px,_1fr))] gap-1 min-w-[1200px]">
          <div className="font-medium text-sm p-2">Date</div>
          {timeBlocks.map((hour) => (
            <div key={hour} className="text-center text-xs p-2">
              {`${hour.toString().padStart(2, '0')}:00`}
            </div>
          ))}

          {Array.from({ length: days }).map((_, dayIndex) => {
            const currentDate = addDays(startDate, dayIndex);
            return (
              <React.Fragment key={dayIndex}>
                <div className="font-medium text-sm p-2 whitespace-nowrap">
                  {format(currentDate, 'EEE, MMM d')}
                </div>
                {timeBlocks.map((hour) => (
                  <TimeBlock
                    key={`${dayIndex}-${hour}`}
                    hour={hour}
                    dayIndex={dayIndex}
                    state={sleepStates[`${dayIndex}-${hour}`]}
                    onSelect={handleBlockSelect}
                    onRightClick={(h, d, activity) => {
                      const key = `${d}-${h}`;
                      setSleepStates(prev => ({
                        ...prev,
                        [key]: {
                          ...prev[key],
                          activity,
                        },
                      }));
                    }}
                    onMiddleClick={(h, d, position) => {
                      const key = `${d}-${h}`;
                      setSleepStates(prev => ({
                        ...prev,
                        [key]: {
                          ...prev[key],
                          position,
                        },
                      }));
                    }}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </div>
      </div>

      {/* Legend */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-sm text-gray-600 bg-gray-50 p-4 rounded-lg">
        <div className="flex items-center space-x-2">
          <Coffee className="w-4 h-4" />
          <span>Right-click: Caffeine</span>
        </div>
        <div className="flex items-center space-x-2">
          <Wine className="w-4 h-4" />
          <span>Right-click: Alcohol</span>
        </div>
        <div className="flex items-center space-x-2">
          <Dumbbell className="w-4 h-4" />
          <span>Right-click: Exercise</span>
        </div>
        <div className="flex items-center space-x-2">
          <BedDouble className="w-4 h-4" />
          <span>Middle-click: Position</span>
        </div>
      </div>

      {/* Sleep Positions Legend */}
      <div className="grid grid-cols-2 md:grid-cols-5 gap-4 text-sm bg-gray-50 p-4 rounded-lg">
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4 bg-indigo-500 rounded-sm flex items-center justify-center">
            <BedDouble className="w-3 h-3 text-white" />
          </div>
          <span>On Back</span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4 bg-blue-500 rounded-sm flex items-center justify-center">
            <ArrowLeft className="w-3 h-3 text-white" />
          </div>
          <span>Left Side</span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4 bg-violet-500 rounded-sm flex items-center justify-center">
            <ArrowRight className="w-3 h-3 text-white" />
          </div>
          <span>Right Side</span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4 bg-purple-500 rounded-sm flex items-center justify-center">
            <ArrowDown className="w-3 h-3 text-white" />
          </div>
          <span>On Front</span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4 bg-pink-500 rounded-sm flex items-center justify-center">
            <Armchair className="w-3 h-3 text-white" />
          </div>
          <span>Sitting</span>
        </div>
      </div>
    </div>
  );
};

export default SleepGrid;