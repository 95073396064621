import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './components/Layout';
import AppRoutes from './routes/AppRoutes';
import { AuthProvider } from './context/AuthContext';
import { ToastProvider } from './context/ToastContext';
import GoogleAnalytics from './components/GoogleAnalytics';

const App = () => {
  return (
    <ToastProvider>
      <AuthProvider>
        <Router>
          <GoogleAnalytics />
          <Layout>
            <AppRoutes />
          </Layout>
        </Router>
      </AuthProvider>
    </ToastProvider>
  );
};

export default App;