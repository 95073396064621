import React, { useState } from 'react';
import { X, Copy, Check, Facebook, Twitter, MessageCircle, Mail } from 'lucide-react';
import { useToast } from '../../context/ToastContext';

interface ShareModalProps {
  onClose: () => void;
  recordingUrl: string | null;
}

const ShareModal: React.FC<ShareModalProps> = ({ onClose, recordingUrl }) => {
  const [copied, setCopied] = useState(false);
  const { showToast } = useToast();

  const handleCopy = async () => {
    if (recordingUrl) {
      try {
        await navigator.clipboard.writeText(recordingUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
        showToast('Link copied to clipboard!', 'success');
      } catch (error) {
        showToast('Failed to copy link', 'error');
      }
    }
  };

  const shareLinks = [
    {
      name: 'Facebook',
      icon: <Facebook className="w-5 h-5" />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(recordingUrl || '')}`
    },
    {
      name: 'Twitter',
      icon: <Twitter className="w-5 h-5" />,
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(recordingUrl || '')}&text=Check out my sleep recording!`
    },
    {
      name: 'WhatsApp',
      icon: <MessageCircle className="w-5 h-5" />,
      url: `https://wa.me/?text=${encodeURIComponent(`Check out my sleep recording! ${recordingUrl}`)}`
    },
    {
      name: 'Email',
      icon: <Mail className="w-5 h-5" />,
      url: `mailto:?subject=Sleep Recording&body=Check out my sleep recording! ${recordingUrl}`
    }
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl shadow-lg max-w-md w-full mx-auto">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl font-semibold">Share Recording</h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="space-y-6">
            {/* Copy Link */}
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={recordingUrl || ''}
                readOnly
                className="flex-1 p-2 border rounded-lg bg-gray-50"
              />
              <button
                onClick={handleCopy}
                className="p-2 text-indigo-600 hover:text-indigo-700"
              >
                {copied ? <Check className="w-5 h-5" /> : <Copy className="w-5 h-5" />}
              </button>
            </div>

            {/* Social Share Buttons */}
            <div className="grid grid-cols-2 gap-4">
              {shareLinks.map((link) => (
                <a
                  key={link.name}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center space-x-2 p-3 rounded-lg border hover:bg-gray-50 transition-colors"
                >
                  {link.icon}
                  <span>{link.name}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;