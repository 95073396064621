import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Moon, Star, BookOpen, Calendar, Trophy,
  ArrowRight, CheckCircle, Mic, Volume2
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import AudioRecorder from '../components/audio/AudioRecorder';
import SleepTrackerPreview from '../components/SleepTrackerPreview';

const Home = () => {
  const { isAuthenticated } = useAuth();
  const [showRecorder, setShowRecorder] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-b from-violet-50 via-indigo-50 to-white">
      {/* Hero Section */}
      <section className="py-8 md:py-16 relative overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <div className="inline-flex items-center px-4 py-2 bg-indigo-100 rounded-full text-indigo-600 mb-8">
              <Star className="w-4 h-4 mr-2" />
              <span className="text-sm font-medium">14-Day Sleep Challenge</span>
            </div>
            
            <h1 className="text-4xl md:text-6xl font-serif font-bold text-gray-900 mb-6 leading-tight">
              Simple Notes
            </h1>
            
            <p className="text-lg md:text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Your bedside companion for better sleep.<br />
              Just like writing in a journal, but smarter.<br />
              Build lasting habits in just 14 days.
            </p>

            <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-12">
              <Link
                to={isAuthenticated ? "/diary" : "/signup"}
                className="w-full sm:w-auto group inline-flex items-center justify-center px-8 py-4 text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 transition-all"
              >
                Start Your Sleep Journey
                <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              
              <button
                onClick={() => setShowRecorder(true)}
                className="w-full sm:w-auto flex items-center justify-center space-x-2 px-8 py-4 text-lg font-medium rounded-full text-indigo-600 bg-indigo-100 hover:bg-indigo-200 transition-all"
              >
                <Mic className="w-5 h-5" />
                <span>Record Snoring</span>
              </button>
            </div>

            {/* Sleep Tracker Preview */}
            <div className="mb-16">
              <SleepTrackerPreview />
            </div>

            {/* Steps Section */}
            <div className="grid md:grid-cols-3 gap-6 mb-12">
              <div className="bg-white rounded-2xl p-6 shadow-lg">
                <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <BookOpen className="w-8 h-8 text-indigo-600" />
                </div>
                <h3 className="text-xl font-bold mb-2">1. Note Before Bed</h3>
                <p className="text-gray-600">
                  Quick bedside notes about your day's activities and sleep routine
                </p>
              </div>

              <div className="bg-white rounded-2xl p-6 shadow-lg">
                <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Calendar className="w-8 h-8 text-indigo-600" />
                </div>
                <h3 className="text-xl font-bold mb-2">2. Track 14 Days</h3>
                <p className="text-gray-600">
                  Follow your personalized 14-day journey with daily encouragement
                </p>
              </div>

              <div className="bg-white rounded-2xl p-6 shadow-lg">
                <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Trophy className="w-8 h-8 text-indigo-600" />
                </div>
                <h3 className="text-xl font-bold mb-2">3. See Results</h3>
                <p className="text-gray-600">
                  Watch your sleep improve as you build better habits
                </p>
              </div>
            </div>

            {/* Features Section */}
            <div className="grid md:grid-cols-2 gap-6 mb-12">
              <div className="bg-white rounded-2xl p-6 shadow-lg">
                <div className="flex items-center space-x-4 mb-4">
                  <div className="bg-indigo-100 p-3 rounded-full">
                    <CheckCircle className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div className="text-left">
                    <h3 className="text-xl font-bold">Daily Encouragement</h3>
                    <p className="text-gray-600">
                      Receive gentle reminders and positive reinforcement through SMS
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl p-6 shadow-lg">
                <div className="flex items-center space-x-4 mb-4">
                  <div className="bg-indigo-100 p-3 rounded-full">
                    <Trophy className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div className="text-left">
                    <h3 className="text-xl font-bold">Achievement System</h3>
                    <p className="text-gray-600">
                      Earn badges and track your progress throughout your journey
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-indigo-600 py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
              Ready to Transform Your Sleep?
            </h2>
            <p className="text-xl text-indigo-100 mb-8">
              Join thousands who've discovered the power of simple bedside notes.
              Start your journey to better sleep today.
            </p>
            <Link
              to="/signup"
              className="inline-flex items-center justify-center px-8 py-4 text-lg font-medium rounded-full text-indigo-600 bg-white hover:bg-indigo-50 transition-all"
            >
              Begin Your Free 14-Day Journey
            </Link>
          </div>
        </div>
      </section>

      {/* Audio Recorder Modal */}
      {showRecorder && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-2xl p-6 max-w-lg w-full mx-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Record Snoring</h3>
              <button 
                onClick={() => setShowRecorder(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
            <AudioRecorder isDarkMode={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;