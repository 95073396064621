import React, { useState, useRef, useEffect } from 'react';
import { Mic, Square, Play, Pause, Volume2, AlertTriangle, Info, Loader, Share2, Save } from 'lucide-react';
import { useToast } from '../../context/ToastContext';
import { useAuth } from '../../context/AuthContext';
import AudioVisualizer from './AudioVisualizer';
import ShareModal from './ShareModal';
import SaveRecordingModal from './SaveRecordingModal';

interface AudioRecorderProps {
  isDarkMode?: boolean;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({ isDarkMode }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [dbThreshold, setDbThreshold] = useState(60);
  const [audioChunks, setAudioChunks] = useState<Array<{ blob: Blob; timestamp: number; volume: number }>>([]);
  const [playingIndex, setPlayingIndex] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showTips, setShowTips] = useState(true);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [recordingUrl, setRecordingUrl] = useState<string | null>(null);

  const { showToast } = useToast();
  const { isAuthenticated } = useAuth();
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioContext = useRef<AudioContext | null>(null);
  const analyser = useRef<AnalyserNode | null>(null);
  const audioElements = useRef<{ [key: number]: HTMLAudioElement }>({});
  const chunks = useRef<Blob[]>([]);

  const initializeAudio = async () => {
    setIsInitializing(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          sampleRate: 44100,
          channelCount: 1
        } 
      });

      setHasPermission(true);
      showToast('Microphone access granted', 'success');
      
      audioContext.current = new AudioContext({
        sampleRate: 44100,
        latencyHint: 'interactive'
      });
      
      analyser.current = audioContext.current.createAnalyser();
      const source = audioContext.current.createMediaStreamSource(stream);
      source.connect(analyser.current);
      analyser.current.fftSize = 2048;

      // Prefer WebM for better compatibility
      const mimeType = MediaRecorder.isTypeSupported('audio/webm;codecs=opus') 
        ? 'audio/webm;codecs=opus'
        : MediaRecorder.isTypeSupported('audio/webm') 
          ? 'audio/webm'
          : 'audio/mp4';

      mediaRecorder.current = new MediaRecorder(stream, {
        mimeType,
        audioBitsPerSecond: 128000
      });
      
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = async () => {
        try {
          const blob = new Blob(chunks.current, { type: mimeType });
          const volume = await detectVolume(blob);
          
          if (volume > dbThreshold) {
            const url = URL.createObjectURL(blob);
            setRecordingUrl(url);
            setAudioChunks(prev => [...prev, {
              blob,
              timestamp: Date.now(),
              volume
            }]);
          }
          
          chunks.current = [];
        } catch (error) {
          console.error('Error processing recording:', error);
          showToast('Error processing recording', 'error');
        }
      };

      setError(null);
    } catch (err: any) {
      setHasPermission(false);
      const errorMessage = err.name === 'NotAllowedError' 
        ? 'Microphone access denied. Please check your browser permissions.'
        : 'Failed to access microphone. Please check your device settings.';
      setError(errorMessage);
      showToast(errorMessage, 'error');
    } finally {
      setIsInitializing(false);
    }
  };

  const detectVolume = async (blob: Blob): Promise<number> => {
    try {
      const ctx = new AudioContext({ sampleRate: 44100 });
      const arrayBuffer = await blob.arrayBuffer();
      
      return new Promise((resolve) => {
        ctx.decodeAudioData(
          arrayBuffer,
          (audioBuffer) => {
            const data = audioBuffer.getChannelData(0);
            let maxAmplitude = 0;
            let rmsSum = 0;
            
            for (let i = 0; i < data.length; i++) {
              const amplitude = Math.abs(data[i]);
              rmsSum += amplitude * amplitude;
              maxAmplitude = Math.max(maxAmplitude, amplitude);
            }
            
            const rms = Math.sqrt(rmsSum / data.length);
            const db = 20 * Math.log10(Math.max(rms, maxAmplitude) / 0.00001);
            
            ctx.close().catch(console.error);
            resolve(Math.round(Math.max(0, Math.min(db, 100))));
          },
          (error) => {
            ctx.close().catch(console.error);
            console.warn('Audio decoding error:', error);
            resolve(60); // Default value on error
          }
        );
      });
    } catch (err) {
      console.error('Error analyzing audio:', err);
      return 60; // Default value on error
    }
  };

  const startRecording = async () => {
    if (!mediaRecorder.current) {
      await initializeAudio();
    }
    
    if (mediaRecorder.current && !isRecording) {
      chunks.current = [];
      mediaRecorder.current.start(1000);
      setIsRecording(true);
      setError(null);
      setShowTips(false);
      showToast('Recording started', 'success');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      showToast('Recording stopped', 'success');
    }
  };

  const handleSave = async (metadata: { title: string; notes: string }) => {
    showToast('Recording saved successfully!', 'success');
  };

  // Cleanup resources on unmount
  useEffect(() => {
    return () => {
      if (mediaRecorder.current && isRecording) {
        mediaRecorder.current.stop();
      }
      if (audioContext.current) {
        audioContext.current.close().catch(console.error);
      }
      audioChunks.forEach(chunk => {
        URL.revokeObjectURL(URL.createObjectURL(chunk.blob));
      });
      if (recordingUrl) {
        URL.revokeObjectURL(recordingUrl);
      }
    };
  }, []);

  return (
    <div className="space-y-6">
      {/* Permission/Status Indicator */}
      <div className={`flex items-center justify-between p-4 rounded-lg ${
        isDarkMode 
          ? 'bg-gray-800' 
          : 'bg-gray-100'
      }`}>
        <div className="flex items-center space-x-3">
          <div className={`w-3 h-3 rounded-full ${
            isRecording 
              ? 'bg-red-500 animate-pulse' 
              : hasPermission 
                ? 'bg-green-500' 
                : hasPermission === false 
                  ? 'bg-red-500'
                  : 'bg-gray-400'
          }`} />
          <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            {isRecording 
              ? 'Recording...' 
              : hasPermission 
                ? 'Ready to record'
                : hasPermission === false 
                  ? 'Microphone access denied'
                  : 'Waiting for permission'}
          </span>
        </div>
        {isInitializing && (
          <Loader className="w-5 h-5 animate-spin text-gray-500" />
        )}
      </div>

      {error && (
        <div className={`p-3 rounded-lg flex items-center space-x-2 ${
          isDarkMode ? 'bg-red-900/50 text-red-200' : 'bg-red-100 text-red-700'
        }`}>
          <AlertTriangle className="w-5 h-5 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <button
            onClick={isRecording ? stopRecording : startRecording}
            disabled={isInitializing}
            className={`p-4 rounded-full transition-colors ${
              isRecording 
                ? isDarkMode 
                  ? 'bg-red-500/20 text-red-400 hover:bg-red-500/30' 
                  : 'bg-red-100 text-red-600 hover:bg-red-200'
                : isDarkMode
                  ? 'bg-indigo-500/20 text-indigo-400 hover:bg-indigo-500/30'
                  : 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200'
            } ${isInitializing ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isInitializing ? (
              <Loader className="w-6 h-6 animate-spin" />
            ) : isRecording ? (
              <Square className="w-6 h-6" />
            ) : (
              <Mic className="w-6 h-6" />
            )}
          </button>
          <div>
            <h3 className={`font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-900'}`}>
              {isRecording ? 'Recording Snore Sounds...' : 'Start Recording'}
            </h3>
            <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {isRecording 
                ? 'Only saving sounds above threshold'
                : 'Tap to start snore detection'}
            </p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <Volume2 className={`w-5 h-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          <input
            type="range"
            min="40"
            max="90"
            value={dbThreshold}
            onChange={(e) => setDbThreshold(Number(e.target.value))}
            className="w-24"
          />
          <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            {dbThreshold}dB
          </span>
        </div>
      </div>

      {isRecording && (
        <AudioVisualizer 
          analyser={analyser.current} 
          isRecording={isRecording}
          dbThreshold={dbThreshold}
          isDarkMode={isDarkMode}
        />
      )}

      {audioChunks.length > 0 && (
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h4 className={`font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-900'}`}>
              Detected Snoring Events
            </h4>
            <div className="flex space-x-2">
              <button
                onClick={() => setShowShareModal(true)}
                className="flex items-center space-x-1 px-3 py-1.5 rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200"
              >
                <Share2 className="w-4 h-4" />
                <span>Share</span>
              </button>
              <button
                onClick={() => setShowSaveModal(true)}
                className="flex items-center space-x-1 px-3 py-1.5 rounded-lg bg-indigo-100 text-indigo-700 hover:bg-indigo-200"
              >
                <Save className="w-4 h-4" />
                <span>Save</span>
              </button>
            </div>
          </div>

          <div className="space-y-2">
            {audioChunks.map((chunk, index) => (
              <div
                key={chunk.timestamp}
                className={`flex items-center justify-between p-3 rounded-lg ${
                  isDarkMode ? 'bg-gray-800' : 'bg-gray-50'
                }`}
              >
                <div className="flex items-center space-x-3">
                  <button
                    onClick={() => {
                      const audio = new Audio(URL.createObjectURL(chunk.blob));
                      audio.play();
                    }}
                    className={`p-2 rounded-full ${
                      isDarkMode 
                        ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' 
                        : 'bg-white shadow-sm hover:bg-gray-50'
                    }`}
                  >
                    <Play className="w-4 h-4" />
                  </button>
                  <div>
                    <p className={`text-sm font-medium ${
                      isDarkMode ? 'text-gray-200' : 'text-gray-900'
                    }`}>
                      Snoring Event {index + 1}
                    </p>
                    <p className={`text-xs ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-500'
                    }`}>
                      {new Date(chunk.timestamp).toLocaleTimeString()}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <Volume2 className={`w-4 h-4 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-400'
                  }`} />
                  <span className={`text-sm ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {chunk.volume}dB
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {showShareModal && (
        <ShareModal
          onClose={() => setShowShareModal(false)}
          recordingUrl={recordingUrl}
        />
      )}

      {showSaveModal && (
        <SaveRecordingModal
          onClose={() => setShowSaveModal(false)}
          onSave={handleSave}
          isAuthenticated={isAuthenticated}
        />
      )}
    </div>
  );
};

export default AudioRecorder;