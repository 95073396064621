import React, { useEffect, useRef } from 'react';

interface AudioVisualizerProps {
  analyser: AnalyserNode | null;
  isRecording: boolean;
  dbThreshold: number;
  isDarkMode?: boolean;
}

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({ 
  analyser, 
  isRecording, 
  dbThreshold,
  isDarkMode 
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationFrameRef = useRef<number>();

  useEffect(() => {
    if (!analyser || !isRecording || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      if (!isRecording) return;

      animationFrameRef.current = requestAnimationFrame(draw);

      try {
        analyser.getByteTimeDomainData(dataArray);

        // Clear canvas
        ctx.fillStyle = isDarkMode ? 'rgba(17, 24, 39, 0.1)' : 'rgba(249, 250, 251, 0.1)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw threshold line
        const thresholdY = canvas.height - ((dbThreshold / 100) * canvas.height);
        ctx.beginPath();
        ctx.strokeStyle = 'rgba(239, 68, 68, 0.6)';
        ctx.lineWidth = 2;
        ctx.setLineDash([5, 5]);
        ctx.moveTo(0, thresholdY);
        ctx.lineTo(canvas.width, thresholdY);
        ctx.stroke();
        ctx.setLineDash([]);

        // Add threshold label
        ctx.fillStyle = 'rgb(239, 68, 68)';
        ctx.font = '12px system-ui';
        ctx.fillText(`${dbThreshold}dB threshold`, 8, thresholdY - 5);

        // Draw waveform
        ctx.lineWidth = 2;
        ctx.strokeStyle = isDarkMode ? 'rgb(129, 140, 248)' : 'rgb(99, 102, 241)';
        ctx.beginPath();

        const sliceWidth = canvas.width / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0;
          const y = (v * canvas.height) / 2;

          if (i === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        ctx.lineTo(canvas.width, canvas.height / 2);
        ctx.stroke();

        // Add glow effect for peaks above threshold
        ctx.shadowBlur = 15;
        ctx.shadowColor = isDarkMode ? 'rgb(129, 140, 248)' : 'rgb(99, 102, 241)';
        ctx.stroke();
      } catch (error) {
        console.warn('Visualizer error:', error);
        // Continue visualization even if there's an error
      }
    };

    draw();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [analyser, isRecording, dbThreshold, isDarkMode]);

  return (
    <div className="relative">
      <canvas
        ref={canvasRef}
        className={`w-full h-32 rounded-xl ${
          isDarkMode ? 'bg-gray-800' : 'bg-gray-50'
        }`}
        width={800}
        height={128}
      />
      <div className={`absolute inset-0 rounded-xl pointer-events-none ${
        isDarkMode 
          ? 'bg-gradient-to-r from-indigo-500/5 to-indigo-600/5' 
          : 'bg-gradient-to-r from-indigo-500/10 to-indigo-600/10'
      }`} />
    </div>
  );
};

export default AudioVisualizer;