import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase, createTestUser, type Profile } from '../lib/supabase';
import { useToast } from './ToastContext';

interface AuthContextType {
  user: User | null;
  profile: Profile | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  isDemoMode: boolean;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string, phone: string) => Promise<void>;
  logout: () => Promise<void>;
  startDemoMode: () => void;
  updateUserSettings: (settings: any) => Promise<void>;
  resendConfirmationEmail: (email: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDemoMode, setIsDemoMode] = useState(false);
  const { showToast } = useToast();
  const siteUrl = import.meta.env.VITE_SITE_URL;

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        fetchProfile(session.user.id);
      }
      setIsLoading(false);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_event, session) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        await fetchProfile(session.user.id);
      } else {
        setProfile(null);
      }
      setIsLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  const fetchProfile = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) throw error;
      setProfile(data);
    } catch (error) {
      console.error('Error fetching profile:', error);
      showToast('Failed to load user profile', 'error');
    }
  };

  const signup = async (email: string, password: string, phone: string) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: siteUrl,
          data: {
            phone,
            name: email.split('@')[0],
          }
        }
      });

      if (error) throw error;
      if (!data.user) throw new Error('No user returned from signup');

      showToast('Please check your email to confirm your account', 'success');
      return data.user;
    } catch (error: any) {
      console.error('Signup error:', error);
      if (error.message.includes('duplicate key')) {
        throw new Error('An account with this email already exists');
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;
      if (data.user) {
        await fetchProfile(data.user.id);
        showToast('Successfully logged in', 'success');
      }
    } catch (error: any) {
      console.error('Login error:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setIsDemoMode(false);
      showToast('Successfully logged out', 'success');
    } catch (error) {
      console.error('Logout error:', error);
      showToast('Failed to log out', 'error');
      throw error;
    }
  };

  const startDemoMode = () => {
    setIsDemoMode(true);
    showToast('Demo mode activated - Try all features!', 'success');
  };

  const updateUserSettings = async (settings: any) => {
    if (!user && !isDemoMode) return;
    
    if (isDemoMode) {
      showToast('Settings updated in demo mode', 'success');
      return;
    }

    try {
      const { error } = await supabase
        .from('profiles')
        .update({ settings })
        .eq('id', user!.id);

      if (error) throw error;
      await fetchProfile(user!.id);
      showToast('Settings updated successfully', 'success');
    } catch (error) {
      console.error('Settings update error:', error);
      showToast('Failed to update settings', 'error');
      throw error;
    }
  };

  const resendConfirmationEmail = async (email: string) => {
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email,
        options: {
          emailRedirectTo: siteUrl
        }
      });
      if (error) throw error;
      showToast('Confirmation email sent', 'success');
    } catch (error) {
      console.error('Error resending confirmation:', error);
      showToast('Failed to resend confirmation email', 'error');
      throw error;
    }
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user,
        profile,
        isAuthenticated: !!user,
        isLoading,
        isDemoMode,
        login,
        signup,
        logout,
        startDemoMode,
        updateUserSettings,
        resendConfirmationEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};