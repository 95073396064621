import React from 'react';
import { Coffee, Wine, Dumbbell, BedDouble, ArrowLeft, ArrowDown } from 'lucide-react';

const SleepTrackerPreview = () => {
  return (
    <div className="bg-white rounded-2xl shadow-lg p-6 max-w-2xl mx-auto">
      <div className="flex items-center justify-between mb-4">
        <div className="flex space-x-2">
          <div className="px-4 py-2 bg-gray-100 rounded-full">
            <span className="flex items-center">
              <span className="mr-2">🌙</span>
              Asleep
            </span>
          </div>
          <div className="px-4 py-2 bg-amber-50 rounded-full">
            <span className="flex items-center">
              <span className="mr-2">☀️</span>
              Awake
            </span>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className="grid grid-cols-[auto_repeat(7,_minmax(60px,_1fr))] gap-1 min-w-[500px]">
          <div className="font-medium text-sm p-2">Time</div>
          {['20:00', '21:00', '22:00', '23:00', '00:00', '01:00', '02:00'].map((time) => (
            <div key={time} className="text-center text-xs p-2">{time}</div>
          ))}
          
          {[1, 2, 3].map((day) => (
            <React.Fragment key={day}>
              <div className="font-medium text-sm p-2">Day {day}</div>
              {Array.from({ length: 7 }).map((_, hour) => {
                let content = null;
                let bgColor = 'bg-gray-50';

                // Day 1: Regular sleep pattern
                if (day === 1 && hour >= 2) {
                  bgColor = 'bg-indigo-500';
                  content = <BedDouble className="w-4 h-4 text-white" />;
                }

                // Day 2: Exercise and different sleep position
                if (day === 2) {
                  if (hour === 1) {
                    bgColor = 'bg-green-200';
                    content = <Dumbbell className="w-4 h-4 text-green-700" />;
                  } else if (hour >= 3) {
                    bgColor = 'bg-violet-500';
                    content = <ArrowDown className="w-4 h-4 text-white" />;
                  }
                }

                // Day 3: Coffee and side sleeping
                if (day === 3) {
                  if (hour === 2) {
                    bgColor = 'bg-amber-200';
                    content = <Coffee className="w-4 h-4 text-amber-700" />;
                  } else if (hour >= 4) {
                    bgColor = 'bg-blue-500';
                    content = <ArrowLeft className="w-4 h-4 text-white" />;
                  }
                }

                return (
                  <div
                    key={hour}
                    className={`h-12 ${bgColor} rounded flex items-center justify-center transition-colors`}
                  >
                    {content}
                  </div>
                );
              })}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mt-6">
        <div className="bg-amber-50 rounded-lg p-3">
          <div className="text-2xl font-bold text-amber-600">7</div>
          <div className="text-sm text-gray-600">Caffeine</div>
        </div>
        <div className="bg-red-50 rounded-lg p-3">
          <div className="text-2xl font-bold text-red-600">3</div>
          <div className="text-sm text-gray-600">Alcohol</div>
        </div>
        <div className="bg-green-50 rounded-lg p-3">
          <div className="text-2xl font-bold text-green-600">1</div>
          <div className="text-sm text-gray-600">Exercise</div>
        </div>
        <div className="bg-indigo-50 rounded-lg p-3">
          <div className="text-2xl font-bold text-indigo-600">37h</div>
          <div className="text-sm text-gray-600">Sleep</div>
        </div>
      </div>
    </div>
  );
};

export default SleepTrackerPreview;