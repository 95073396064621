import React, { useState, useEffect } from 'react';
import { Calendar, ChevronDown, Target, Coffee, Wine, Dumbbell, Moon } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../lib/supabase';
import { useToast } from '../../context/ToastContext';
import { SleepState } from './types';
import SleepGrid from './SleepGrid';
import Achievements from './Achievements';
import { useStreakCounter } from '../../hooks/useStreakCounter';

const SleepDiary = () => {
  const { streak } = useStreakCounter();
  const [sleepStates, setSleepStates] = useState<SleepState>({});
  const [showGoalModal, setShowGoalModal] = useState(false);
  const [sleepGoal, setSleepGoal] = useState({ hours: 8, bedtime: '22:00' });
  const { user, isDemoMode } = useAuth();
  const { showToast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    loadSleepData();
  }, [user, isDemoMode]);

  const loadSleepData = async () => {
    if (isDemoMode) {
      const demoData = {
        "0-22": { isAsleep: true, position: "supine" },
        "0-23": { isAsleep: true, position: "leftLateral" },
        "1-0": { isAsleep: true, position: "prone" },
        "1-1": { isAsleep: true, position: "rightLateral" },
      };
      setSleepStates(demoData);
      return;
    }

    if (!user) return;

    try {
      const { data, error } = await supabase
        .from('sleep_entries')
        .select('*')
        .eq('user_id', user.id)
        .order('date', { ascending: false })
        .limit(1);

      if (error) throw error;
      if (data?.[0]?.sleep_data) {
        setSleepStates(data[0].sleep_data);
      }
    } catch (error) {
      console.error('Error loading sleep data:', error);
      showToast('Failed to load sleep data', 'error');
    }
  };

  const calculateStats = () => {
    const stats = {
      coffee: 0,
      alcohol: 0,
      exercise: 0,
      totalSleepHours: 0,
    };

    Object.values(sleepStates).forEach((state) => {
      if (state.isAsleep) stats.totalSleepHours++;
      if (state.activity === 'coffee') stats.coffee++;
      if (state.activity === 'alcohol') stats.alcohol++;
      if (state.activity === 'exercise') stats.exercise++;
    });

    return stats;
  };

  const stats = calculateStats();

  const saveSleepData = async (newStates: SleepState) => {
    if (!user || isSaving) return;

    if (isDemoMode) {
      setSleepStates(newStates);
      return;
    }

    try {
      setIsSaving(true);
      const { error } = await supabase
        .from('sleep_entries')
        .upsert({
          user_id: user.id,
          sleep_data: newStates,
          date: new Date().toISOString().split('T')[0]
        }, {
          onConflict: 'user_id,date'
        });

      if (error) throw error;
      showToast('Sleep data saved', 'success');
    } catch (error) {
      console.error('Error saving sleep data:', error);
      showToast('Failed to save sleep data', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (Object.keys(sleepStates).length > 0) {
        saveSleepData(sleepStates);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [sleepStates]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 gap-4">
          <h2 className="text-2xl font-bold text-gray-900">Sleep Journal</h2>
          <button
            onClick={() => setShowGoalModal(true)}
            className="flex items-center space-x-2 px-4 py-2 bg-indigo-100 text-indigo-600 rounded-lg hover:bg-indigo-200 transition-colors"
          >
            <Target className="w-5 h-5" />
            <span>Set Sleep Goal</span>
          </button>
        </div>

        {/* Sleep Grid */}
        <div className="bg-white rounded-2xl shadow-lg p-4 md:p-6 mb-8">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <Calendar className="w-5 h-5 text-indigo-500" />
              <h2 className="text-xl font-bold text-gray-900">Sleep Diary</h2>
            </div>
            <button className="flex items-center space-x-2 px-4 py-2 bg-gray-100 rounded-lg text-gray-700 hover:bg-gray-200 transition-colors">
              <span className="hidden md:inline">Last 2 Weeks</span>
              <span className="md:hidden">2w</span>
              <ChevronDown className="w-4 h-4" />
            </button>
          </div>

          <div className="overflow-x-auto">
            <SleepGrid sleepStates={sleepStates} setSleepStates={setSleepStates} />
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
          <StatCard
            icon={<Coffee className="w-6 h-6 text-amber-600" />}
            title="Caffeine"
            value={stats.coffee}
            label="times"
            color="bg-amber-50"
          />
          <StatCard
            icon={<Wine className="w-6 h-6 text-red-600" />}
            title="Alcohol"
            value={stats.alcohol}
            label="times"
            color="bg-red-50"
          />
          <StatCard
            icon={<Dumbbell className="w-6 h-6 text-green-600" />}
            title="Exercise"
            value={stats.exercise}
            label="times"
            color="bg-green-50"
          />
          <StatCard
            icon={<Moon className="w-6 h-6 text-indigo-600" />}
            title="Sleep"
            value={stats.totalSleepHours}
            label="hours"
            color="bg-indigo-50"
          />
        </div>

        {/* Achievements */}
        <Achievements streak={streak} lastEntry={selectedDate} />
      </div>

      {/* Goal Setting Modal */}
      {showGoalModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-xl p-6 max-w-md w-full mx-auto">
            <h3 className="text-xl font-bold text-gray-900 mb-4">Set Your Sleep Goal</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Hours of Sleep
                </label>
                <input
                  type="number"
                  min="4"
                  max="12"
                  value={sleepGoal.hours}
                  onChange={(e) => setSleepGoal({ ...sleepGoal, hours: Number(e.target.value) })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Target Bedtime
                </label>
                <input
                  type="time"
                  value={sleepGoal.bedtime}
                  onChange={(e) => setSleepGoal({ ...sleepGoal, bedtime: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
                />
              </div>
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => setShowGoalModal(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setShowGoalModal(false);
                    showToast('Sleep goal updated', 'success');
                  }}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Save Goal
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const StatCard: React.FC<{
  icon: React.ReactNode;
  title: string;
  value: number;
  label: string;
  color: string;
}> = ({ icon, title, value, label, color }) => (
  <div className={`${color} rounded-lg p-4`}>
    <div className="flex items-center space-x-3 mb-2">
      {icon}
      <h4 className="font-medium text-gray-900">{title}</h4>
    </div>
    <div className="text-2xl font-bold text-gray-900">
      {value}
      <span className="text-sm font-normal text-gray-600 ml-1">{label}</span>
    </div>
  </div>
);

export default SleepDiary;