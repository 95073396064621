import { createClient } from '@supabase/supabase-js';
import type { Database } from './database.types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const siteUrl = import.meta.env.VITE_SITE_URL;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce',
    redirectTo: siteUrl
  }
});

// Create and auto-confirm test user
export const createTestUser = async () => {
  try {
    // First try to sign in
    const { data: existingUser } = await supabase.auth.signInWithPassword({
      email: 'test@logmysleep.com',
      password: 'test123456'
    });

    if (existingUser?.user) {
      console.log('Test user exists, signing in');
      return existingUser.user;
    }

    // If sign in fails, create new user
    const { data: { user }, error: signUpError } = await supabase.auth.signUp({
      email: 'test@logmysleep.com',
      password: 'test123456',
      options: {
        emailRedirectTo: siteUrl,
        data: {
          name: 'Test User',
          phone: '+15555555555'
        }
      }
    });

    if (signUpError) throw signUpError;
    if (!user) throw new Error('No user returned from signup');

    // Wait a moment for the user to be created
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Update user status directly in the database
    const { error: updateError } = await supabase
      .from('profiles')
      .update({
        status: 'active',
        name: 'Test User',
        phone: '+15555555555',
        settings: {
          smsReminders: false,
          emailAlerts: true,
          twoFactorEnabled: false
        }
      })
      .eq('id', user.id);

    if (updateError) throw updateError;

    // Update auth.users directly to confirm email
    await supabase.auth.updateUser({
      data: {
        email_confirmed: true
      }
    });

    return user;
  } catch (error) {
    console.error('Error creating test user:', error);
    throw error;
  }
};

// Initialize test user in development
if (import.meta.env.DEV) {
  createTestUser().catch(console.error);
}

export type { Profile } from './database.types';